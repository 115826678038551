"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usingFeature = void 0;
var lodash_1 = require("lodash");
var Environment_1 = require("@CommonFrontendBackend/Environment");
var usingFeature = function (featureFlags, environment) {
    return function (featureName) {
        if ((0, lodash_1.startsWith)(featureName, "optOut")) {
            return (0, lodash_1.includes)(featureFlags, featureName);
        }
        else {
            return (((0, Environment_1.isNonProductionEnvironment)(environment) && (0, lodash_1.includes)(featureFlags, "canary")) ||
                (0, lodash_1.includes)(featureFlags, featureName));
        }
    };
};
exports.usingFeature = usingFeature;
module.exports = {
    usingFeature: exports.usingFeature,
};
