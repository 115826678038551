"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLocalEnvironment = exports.isNonProductionEnvironment = exports.Environment = void 0;
var Environment;
(function (Environment) {
    Environment["Production"] = "production";
    Environment["Dogfood"] = "dogfood";
    Environment["Test"] = "test";
})(Environment || (exports.Environment = Environment = {}));
var isNonProductionEnvironment = function (environment) {
    return environment !== Environment.Production;
};
exports.isNonProductionEnvironment = isNonProductionEnvironment;
var isLocalEnvironment = function (hostname) {
    return hostname.includes("qwilr.local");
};
exports.isLocalEnvironment = isLocalEnvironment;
