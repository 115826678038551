// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi, projectCreatorProfile, badgeClickUrl) {
    var getReferralTag;
    getReferralTag = function (branding) {
      if (branding === "minimal") {
        return "powered-by-qwilr";
      } else {
        return "powered-by-qwilr-impressively-beautiful-business-documents";
      }
    };
    return function (utmMedium, className) {
      return createUi(require("./PoweredByQwilr.html"), {
        branding: projectCreatorProfile.branding,
        link:
          "//" +
          badgeClickUrl +
          "?utm_source=qwilr-branding&utm_medium=" +
          utmMedium +
          "&utm_campaign=" +
          getReferralTag(projectCreatorProfile.branding),
        className: className,
      });
    };
  };
}.call(this));
